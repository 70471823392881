import Cookie from "js-cookie";
import request from "@/utils/request.ts";
import file from "./file";
import Exif from "exif-js";
import { compress } from "@/utils/compress";
import { v4 as uuidv4 } from "uuid";

const OSS = require("ali-oss");
const tokenName = "credentials";
const baseUrl = "/file";

/**
 * 获取文件后缀名
 * @param file
 * @returns {string}
 */
export function getType(file: any) {
  const filename = file;
  const index1 = filename.lastIndexOf(".");
  const index2 = filename.length;
  const type = filename.substring(index1, index2);
  return type;
}

/**
 * 获取临时身份验证
 */
export function stsGet() {
  return request({
    url: baseUrl + "/sts/get",
    method: "get",
  });
}

/**
 * 创建OSSClient实例
 */
export async function getClient() {
  let credentials = null;
  credentials = Cookie.getJSON(tokenName);
  if (credentials === null || typeof credentials === "undefined") {
    const response: any = await stsGet();
    credentials = response.credentials;
    Cookie.set("credentials", credentials, {
      expires: new Date(credentials.expiration),
    });
  }
  return new OSS({
    accessKeyId: credentials.accessKeyId,
    accessKeySecret: credentials.accessKeySecret,
    stsToken: credentials.securityToken,
    endpoint: "https://oss-cn-shanghai.aliyuncs.com",
    // bucket: 'ittstest'
    bucket: "wuxi-construction",
  });
  // return client
  // await client.put('file2.txt', new OSS.Buffer('2222'));
  // console.log("---------2-----")
}

/**
 * 简单上传
 *
 * 支持File对象、Blob数据、以及OSS Buffer。
 *
 * const data = '<File Object>‘;
 *
 * or const data = new Blob('content');
 *
 * or const data = new OSS.Buffer('content'));
 * @param {*} objectKey 文件名（例如file.txt）或目录（例如abc/test/file.txt）
 * @param {*} data 文件内容
 */
const simpleUpload = async function simpleUpload(objectKey: any, data: any) {
  const client = await getClient();
  const uuid = uuidv4();
  const suffix = getType(objectKey);
  const result = await client.put(uuid + suffix, data);
  const fileData = {
    name: result.name,
    url: result.url,
    originalName: objectKey,
    suffix: suffix,
    size: data.size,
  };
  const res = file.save(fileData);
  return res;
};
/**
 * 上传文件
 * @param dir {String} dir 文件目录，以/结尾
 * @param objectKey 对象名称
 * @param data 文件内容
 * @param isCompress 是否压缩 Boolean
 * @returns {Promise<*>}
 */
const uploadFile = async function uploadFile(
  dir: any,
  objectKey: any,
  data: any,
  isCompress: any
) {
  let fileContent = data;
  // fileContent = await fixImageOrientation(data)
  if (isCompress) {
    fileContent = await compress(data);
  }
  // console.log('----图片压缩----', fileContent)
  const client = await getClient();
  const uuid = uuidv4();
  const suffix = getType(objectKey);
  const result = await client.put(dir + uuid + suffix, fileContent);
  const fileData = {
    name: result.name,
    url: result.url,
    originalName: objectKey,
    suffix: suffix,
    size: fileContent.size,
  };
  return file.save(fileData);
};
/**
 * 查询文件列表
 * @param {String} dir 文件目录，以/结尾
 */
const listDir = async function listDir(dir: any) {
  const client = await getClient();
  const result = client.list({
    prefix: dir,
    delimiter: "/",
  });
  return result;
};
/**
 * 删除对象
 * @param objectKey 对象名称
 * @returns {Promise<*>}
 */
const deleteObj = async function deleteObj(objectKey: any) {
  const client = await getClient();
  const result = client.delete(objectKey);
  return result;
};
/**
 * 删除文件
 * @param id 文件id
 * @param objectKey 对象名称（origin_name）
 * @returns {Promise<void>}
 */
const removeFile = async function remove(id: any, objectKey: any) {
  const client = await getClient();
  client.delete(objectKey);
  const res = await file.remove({ id: id });
  return res;
};
/**
 * 获取blob文件
 * @param data
 */
const getFileBlob = async function getFileBlob(url: any, name: any) {
  const res = await request({ url: url, method: "get", responseType: "blob" });
  download(res.data, name, res.headers["content-type"]);
};
function download(fileData: any, filename: any, type: any) {
  const blob = new Blob([fileData], { type: type });
  const elment: any = window.navigator.msSaveOrOpenBlob;
  if (elment) {
    navigator.msSaveBlob(blob, filename);
  } else {
    const a = document.createElement("a");
    let url = window.URL.createObjectURL(blob);
    if (!url) {
      url = window.webkitURL.createObjectURL(blob);
    }
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
/**
 * 修正图片旋转角度问题
 * @param {file} 原图片
 * @return {Promise} resolved promise 返回纠正后的新图片
 */
function fixImageOrientation(file: any) {
  return new Promise((resolve, reject) => {
    // 获取图片
    const img: any = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onerror = () => resolve(file);
    img.onload = () => {
      // 获取图片元数据（EXIF 变量是引入的 exif-js 库暴露的全局变量）
      Exif.getData(img, function () {
        // 获取图片旋转标志位
        const orientation = Exif.getTag(Exif, "Orientation");
        // console.log(orientation)
        // 根据旋转角度，在画布上对图片进行旋转
        if (orientation === 3 || orientation === 6 || orientation === 8) {
          const canvas = document.createElement("canvas");
          const ctx: any = canvas.getContext("2d");
          switch (orientation) {
            case 3: // 旋转180°
              canvas.width = img.width;
              canvas.height = img.height;
              ctx.rotate((180 * Math.PI) / 180);
              ctx.drawImage(
                img,
                -img.width,
                -img.height,
                img.width,
                img.height
              );
              break;
            case 6: // 旋转90°
              canvas.width = img.height;
              canvas.height = img.width;
              ctx.rotate((90 * Math.PI) / 180);
              ctx.drawImage(img, 0, -img.height, img.width, img.height);
              break;
            case 8: // 旋转-90°
              canvas.width = img.height;
              canvas.height = img.width;
              ctx.rotate((-90 * Math.PI) / 180);
              ctx.drawImage(img, -img.width, 0, img.width, img.height);
              break;
          }
          // 返回新图片
          canvas.toBlob((file) => resolve(file), "image/jpeg", 0.92);
        } else {
          return resolve(file);
        }
      });
    };
  });
}
/**
 * 压缩图片
 * @param {file} 输入图片
 * @returns {Promise} resolved promise 返回压缩后的新图片
 */
function compressImage(file: any) {
  return new Promise(function (resolve, reject) {
    if (file.size < 512 * 1024) {
      resolve(file);
    }
    const name = file.name; // 文件名
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      const src = e.target.result;

      const img = new Image();
      img.src = src;
      img.onload = (e) => {
        const w = img.width;
        const h = img.height;
        const quality = 70 / (file.size / 1024); // 默认图片质量为0.92
        const canvas = document.createElement("canvas");
        const ctx: any = canvas.getContext("2d");
        // 创建属性节点
        const anw: any = document.createAttribute("width");
        anw.nodeValue = w;
        const anh: any = document.createAttribute("height");
        anh.nodeValue = h;
        canvas.setAttributeNode(anw);
        canvas.setAttributeNode(anh);
        // quality值越小，所绘制出的图像越模糊
        ctx.fillStyle = "#fff";
        // ctx.fillRect(0, 0, h, w)
        ctx.drawImage(img, 0, 0, w, h);
        let base64 = null;
        base64 = canvas.toDataURL("image/jpeg", quality);
        // while (base64 == null || base64.length > 100 * 1024) {
        //   base64 = canvas.toDataURL('image/jpeg', quality) // 图片格式jpeg或webp可以选0-1质量区间
        //   quality -= 0.05
        // }
        // 返回base64转blob的值
        // console.log(`原图${(src.length / 1024).toFixed(2)}kb`, `新图${(base64.length / 1024).toFixed(2)}kb`)
        // 去掉url的头，并转换为byte
        const bytes = window.atob(base64.split(",")[1]);
        // 处理异常,将ascii码小于0的转换为大于0
        const ab = new ArrayBuffer(bytes.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < bytes.length; i++) {
          ia[i] = bytes.charCodeAt(i);
        }
        file = new Blob([ab], { type: "image/jpeg" });
        file.name = name;

        resolve(file);
      };
      img.onerror = (e) => {
        reject(e);
      };
    };
    reader.onerror = (e) => {
      reject(e);
    };
  });
}
const compressImage2 = function (file: any) {
  return new Promise(function (resolve, reject) {
    // 图片小于1M不压缩
    const name = file.name; // 文件名
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e: any) => {
      const src = e.target.result;

      const img = new Image();
      img.src = src;
      img.onload = (e) => {
        const w = img.width;
        const h = img.height;
        let quality = 0.8; // 默认图片质量为0.92
        // 生成canvas

        // 铺底色 PNG转JPEG时透明区域会变黑色

        let Orientation = null;
        let degree = 0;
        Exif.getData(file, function () {
          const canvas = document.createElement("canvas");
          const ctx: any = canvas.getContext("2d");
          // 创建属性节点
          const anw: any = document.createAttribute("width");
          anw.nodeValue = w;
          const anh: any = document.createAttribute("height");
          anh.nodeValue = h;
          canvas.setAttributeNode(anw);
          canvas.setAttributeNode(anh);
          Orientation = Exif.getTag(Exif, "Orientation");
          // console.log(Orientation)
          if (Orientation === 3 || Orientation === 6 || Orientation === 8) {
            switch (Orientation) {
              // iphone横屏拍摄，此时home键在左侧
              case 3:
                degree = 180;
                canvas.width = w;
                canvas.height = h;
                ctx.rotate((180 * Math.PI) / 180);
                ctx.fillStyle = "#fff";
                // ctx.fillRect(0, 0, h, w)
                ctx.drawImage(img, -w, -h, w, h);

                break;
              // iphone竖屏拍摄，此时home键在下方(正常拿手机的方向)
              case 6:
                degree = 90;
                degree = 270;
                canvas.width = h;
                canvas.height = w;
                ctx.rotate((90 * Math.PI) / 180);
                ctx.fillStyle = "#fff";
                // ctx.fillRect(0, 0, h, w)
                ctx.drawImage(img, 0, -h, w, h);
                break;
              // iphone竖屏拍摄，此时home键在上方
              case 8:
                degree = 270;
                canvas.width = h;
                canvas.height = w;
                ctx.rotate((-90 * Math.PI) / 180);
                ctx.fillStyle = "#fff";
                // ctx.fillRect(0, 0, h, w)
                ctx.drawImage(img, -w, 0, w, h);
                break;
              default:
                // console.log('====', Orientation)
                ctx.fillStyle = "#fff";
                // ctx.fillRect(0, 0, h, w)
                ctx.drawImage(img, 0, 0, w, h);
                break;
            }
          } else {
            ctx.fillStyle = "#fff";
            // ctx.fillRect(0, 0, h, w)
            ctx.drawImage(img, 0, 0, w, h);
          }
          // quality值越小，所绘制出的图像越模糊
          let base64 = null;
          while (base64 == null || base64.length > 512 * 1024) {
            base64 = canvas.toDataURL("image/jpeg", quality); // 图片格式jpeg或webp可以选0-1质量区间
            quality -= 0.2;
          }
          // 返回base64转blob的值
          // console.log(`原图${(src.length / 1024).toFixed(2)}kb`, `新图${(base64.length / 1024).toFixed(2)}kb`)
          // 去掉url的头，并转换为byte
          const bytes = window.atob(base64.split(",")[1]);
          // 处理异常,将ascii码小于0的转换为大于0
          const ab = new ArrayBuffer(bytes.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i);
          }
          file = new Blob([ab], { type: "image/jpeg" });
          file.name = name;

          resolve(file);
        });
      };
      img.onerror = (e) => {
        reject(e);
      };
    };
    reader.onerror = (e) => {
      reject(e);
    };
  });
};

export default {
  simpleUpload,
  listDir,
  deleteObj,
  removeFile,
  uploadFile,
  getFileBlob,
};
