import request from "@/utils/request.ts";

export default {
  /**
   * 新增
   * @param data
   */
  save(data: any) {
    return request({
      url: "/file/save",
      method: "post",
      data: data,
    });
  },
  /**
   * 获取一条数据
   * @param data
   */
  page(data: any) {
    return request({
      url: "/file/get",
      method: "get",
      params: data,
    });
  },
  /**
   * 删除
   * @param data
   */
  remove(data: any) {
    return request({
      url: "/file/remove",
      method: "get",
      params: data,
    });
  },
  /**
   * 获取uuid
   * @param data
   */
  getUUid() {
    return request({
      url: "/file/getUUid",
      method: "get",
    });
  },
};
