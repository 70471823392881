//压缩图片
export async function compress(file: any) {
  let res = null;
  const fileName = file.name;
  const reader = new FileReader();
  reader.readAsDataURL(file);
  const p = new Promise(
    await function (resolve, reject) {
      reader.onload = function (e) {
        resolve(e);
      };
      reader.onerror = function (e) {
        reject(e);
      };
    }
  );
  let p2;

  p.then(async (e: any) => {
    const ratio = 0.7;
    const result = e.target.result;

    p2 = new Promise(function (resolve, reject) {
      const image = new Image();
      image.src = result; // 设置image的地址为base64的地址
      image.onload = function (e) {
        resolve(image);
      };
      image.onerror = function (e) {
        reject(e);
      };
    });
    p2.then((e: any) => {
      const canvas = document.createElement("canvas");
      // var canvas = document.querySelector("canvas");
      const context: any = canvas.getContext("2d");
      // 设置canvas的画布宽度为图片宽度
      canvas.width = e.width / 2.5;
      canvas.height = e.height / 2.5;
      // 在canvas上绘制图片
      context.drawImage(e, 0, 0, e.width / 2.5, e.height / 2.5);

      // dataUrl 为压缩后的图片资源，可将其上传到服务器
      const dataUrl = canvas.toDataURL("image/jpeg", ratio); // 0.92为压缩比，可根据需要设置，设置过小会影响图片质量
      // let length = toBlob(dataUrl);
      res = toFile(dataUrl, fileName);
    });
  });
  await p;
  await p2;

  return res;
}

export function toBlob(dataURL: any) {
  const arr = dataURL.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], {
    type: mime,
  });
}

export function toFile(data: any, fileName: any) {
  const dataArr = data.split(",");
  const byteString = atob(dataArr[1]);

  const u8Arr = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    u8Arr[i] = byteString.charCodeAt(i);
  }
  return new File([u8Arr], fileName + ".jpg", {
    type: "image/jpeg",
    endings: "native",
  });
}
